<style>
    /* Set the page size to A4 and the orientation to landscape */
    @page :left {
        size: A4 portrait;
    }

    /* Set the font size to 12px for printing */
    @media print {
        body {
            /* font-size: 14px; */
            font-family: 'Courier New', Courier, monospace;
        }
        .break-before{
            page-break-before:always;
        }
        .print-foot {
            display:block !important;
        }
        .page-number:before {
            content: "Page: " counter(page);
        }
        /* .print-foot-2 {
            bottom: 405px !important;
        } */
    }
    *{
        /* font-size: 14px; */
        font-family: 'Courier New', Courier, monospace;
    }

    #logo {
        position: absolute;
        top: 2px;
        right: 2px;
        width: 200px;
        height: 100px;
    }

    .title ,.title th{
        font-size: small;
        font-weight: bold;
        margin-bottom: 20px;
    }
    .head p,.head th,.head td{
        font-size: small;
        font-weight: bold;
        color: black;
    }
    .body p,.body td{
        font-size: small;
    }

    table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
    }

    #no-border {
        border: none;
    }

    td, th {
        border: 2px solid #000000;
        text-align: left;
        padding: 8px;
        line-height: 2;
    }

    .lh-1,.lh-1 th{
        line-height: 1;
    }
    .lh-1\/2,.lh-1\/2 p{
        line-height: 1.5;
    }

    .remove_border tr th{
        border: none;
    }
    .bb-dashed{
        border-bottom: dashed;
        border-bottom-width: medium;

    }
    .bt-dashed{
        border-top: dashed !important;
        border-top-width: medium;
    }
    .no-border th,.no-border td{
        border: none;
        padding: 2px;
    }

    #table-list {
        margin-top: 80px;
    }

    #info {
        margin-top: 20px;
        width: 40%;
        float: left;
        margin-bottom: 20px;
    }

    .space-for-logo {
        width: 200px;
        height: 100px;
    }

    .text-uppercase {
        text-transform: uppercase !important;
    }
    .text-capitalize {
        text-transform: capitalize !important;
    }
    .print-foot {
        position: relative;
        left: 0;
        right: 0px;
        display: block;
    }
    .print-foot-hide {
        display:block
    }
</style>

<template>
    <div>
        <table>
                <thead>
                    <tr class="bb-dashed bt-dashed no-border title head">
                        <th width="29%">Product</th>
                        <th width="7%">Qty</th>
                        <th width="5%">UoM</th>
                        <th width="10%">Price Value</th>
                        <th width="10%">Discount</th>
                        <th width="7%">Net Sales</th>
                    </tr>
                </thead>

                <tbody>
                    <template v-for="(item, index) in salesOrder.Items">
                        <!-- first row -->
                        <template v-for="(dataLine,i) in item.lines">
                            <tr class="no-border body head" :key="index+'-'+i" v-if="salesOrder.Items[index].lines[i].page==page">
                                <td class="align-top">
                                    {{ dataLine.item_name}}
                                </td>
                                <td class="align-top">
                                    {{ formatCurrency(dataLine.qty) }}
                                </td>
                                <td class="align-top">
                                    {{ dataLine.unit }}
                                </td>
                                <td class="align-top">
                                    {{ formatCurrency(dataLine.price) }}
                                </td>
                                <td class="align-top">
                                    {{ formatCurrency(dataLine.discount) }}
                                </td>
                                <td class="align-top">
                                    {{ formatCurrency(
                                        (parseFloat(dataLine.subtotal))-
                                        parseFloat(dataLine.discount)+
                                        parseFloat(dataLine.rounding)+
                                        parseFloat(dataLine.gross_up)) }}
                                </td>
                            </tr>
                        </template>
                    </template>
                </tbody>
                <tfoot v-if="salesOrder.total_page==page">
                    <tr class="bt-dashed no-border title lh-1 head">
                        <th colspan="2"></th>
                        <th colspan="3">DPP</th>
                        <th class="text-right">{{ formatCurrency(salesOrder.total_dpp)}}</th>
                    </tr>
                    <tr class="no-border title lh-1 head">
                        <th colspan="2"></th>
                        <th colspan="3">PPN</th>
                        <th class="text-right">{{ formatCurrency(salesOrder.TaxAmount)}}</th>
                    </tr>
                    <tr class="bb-dashed no-border title lh-1 head">
                        <th colspan="2"></th>
                        <th colspan="3">TOTAL</th>
                        <th class="text-right">{{ formatCurrency(salesOrder.Total) }}</th>
                    </tr>
                </tfoot>
            </table>
    </div>
</template>

<script>
import moment from "moment";
import angkaTerbilang from "@develoka/angka-terbilang-js";
// import moment from "moment-timezone";
// import logoSDN from "../../../assets/images/logo/logo-sdn.png";

export default {
    name: "PrintPdf",
    props: ["salesOrder","page"],
    data() {
        return {
        }
    },
    watch:{
    },
    mounted() {
        console.log(this.salesOrder)
    },
    methods: {
        formatCurrency(nominal) {
            if(nominal && nominal > 0) { // nominal > 0 untuk handle return NaN
                nominal = (nominal/1);
                return nominal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            } else {
                return 0;
            }
        },
        terbilang(angka) {
            return angkaTerbilang(angka).toUpperCase() + " RUPIAH"
        },
        capitalize(text){
            const word = text.toLowerCase().split(" ");
            for (var i = 0; i < word.length; i++) {
                word[i] = word[i].charAt(0).toUpperCase() + word[i].slice(1);
            }
            const new_text = word.join(" ");
            return new_text
        }
    },
    computed: {
        formatDate: () => {
            return (val) => {
                const momentDate = moment.utc(val);
                return momentDate.format('DD MMM YYYY');
            };
        },
    },
}
</script>